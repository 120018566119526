import { useRef, useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { SiteContext } from '../../context/SiteContext';
import ApiRequest from '../../api/axios';
import { TextField, Box, Button } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AccountCircle from '@mui/icons-material/AccountCircle';
import './login.scss';
import md5 from 'md5';
import Authen from '../../common/helper';
import Constant from '../../common/constant/constant';
import { useSnackbar } from 'notistack';

const Login = () => {
	const { dispatch } = useContext(SiteContext);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const location = useLocation();
	const from = location.state?.from?.pathname || Constant.PATH_SLASH;

	const userRef = useRef();

	const [email, setEmail] = useState('');
	const [password, setPwd] = useState('');
	// const [errMsg, setErrMsg] = useState('');

	const [showPassword, setShowPassword] = useState(false);
	// const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	useEffect(() => {
		userRef.current.focus();
	}, []);

	const handleSubmit = async (e) => {
		e.preventDefault();
		const body = {
			email: email,
			password: md5(password),
		};
		const response = await ApiRequest(
			Constant.POST,
			Constant.LOGIN_URL,
			body
		);
		if (!response?.data) return;
		enqueueSnackbar('Đăng nhập thành công', { variant: 'success' });
		const menu = response?.data?.data.user.menus;
		const accessToken = response?.data?.data?.tokens?.accessToken;
		const name = response?.data?.data.user.name;
		Authen(accessToken);
		const login = {
			accessToken,
			menu: menu,
			id: response?.data?.data.user?._id,
			project: response?.data?.data?.user?.project,
		};
		window.localStorage.setItem('LOGIN', JSON.stringify(login));
		dispatch({ type: 'LOGIN', login: { email, name, ...login } });
		setEmail('');
		setPwd('');
		navigate(from, { replace: true });
	};

	return (
		<section>
			<div className="w-100">
				<div>
					<img
						src="./asset/images/khung-logo.png"
						alt="banner"
						width="100%"
					/>
				</div>
				<form onSubmit={handleSubmit} className="form-signin m-auto">
					<Box m="10px" display="flex" alignItems="center">
						<TextField
							id="input-with-icon-textfield"
							variant="outlined"
							placeholder="Enter your email"
							onChange={(e) => setEmail(e.target.value)}
							value={email}
							ref={userRef}
							required
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<AccountCircle />
									</InputAdornment>
								),
							}}
							style={{ display: 'flex', flex: '1' }}
							size="small"
						/>
					</Box>
					<Box m="10px" display="flex" alignItems="center">
						<OutlinedInput
							id="outlined-adornment-password"
							onChange={(e) => setPwd(e.target.value)}
							value={password}
							required
							type={showPassword ? 'text' : 'password'}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
										edge="end"
									>
										{showPassword ? (
											<VisibilityOff />
										) : (
											<Visibility />
										)}
									</IconButton>
								</InputAdornment>
							}
							label="Password"
							style={{ display: 'flex', flex: '1' }}
							size="small"
						/>
					</Box>
					<div className="div-login button-login">
						<Box m="10px" display="flex" alignItems="center">
							<Button
								type="submit"
								variant="contained"
								className="button-login"
								style={{ textTransform: 'none' }}
							>
								Đăng nhập
							</Button>
						</Box>
					</div>
				</form>
			</div>
		</section>
	);
};

export default Login;
