import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './custommodal.scss';
import { useEffect, useState } from 'react';
import Constant from '../../common/constant/constant';
import JSONView from 'react-json-view';
import { Input, Box } from "@mui/material";
import ApiRequest from '../../api/axios';

const ModalCompleteTransaction = ({
	data,
	btnCloseShow,
	show,
	onHide,
}) => {
	const [receiptToken, setReceiptToken] = useState(Constant.EMPTY);

	const submit = async () => {
		console.log({data, receiptToken});
		const body = {
			receiptData: receiptToken,
			extraData: data.extraData || '',
			purchaseCode: data.purchaseCode,
		}
		const response = await ApiRequest(Constant.POST, Constant.PURCHASE_URL + `/completeTransaction`, body);
		if (response?.data?.success) {
			alert('Complete transaction success');

		} else {
			alert('Complete transaction failed');
		}
	}
	const handleInputReceiptToken = (event) => {
		setReceiptToken(event.target.value);
	}
	return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-90w max-width-70"
      // centered
    >
      <Modal.Body className="bodyModal">
        <Box m="20px" className="infomation">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <h3>Complete transaction</h3>
          </Box>
          <Box className="flex">
						<span>Purchase code: </span>
            <Input disabled value={data.purchaseCode}></Input>
          </Box>
          <Box className="flex">
            <span>Extra data: </span>
            <Input disabled value={data.extraData} />
          </Box>
          <Box className="flex">
						<span>Receipt Token: </span>
            <Input value={receiptToken} onChange={handleInputReceiptToken}/>
          </Box>
        </Box>
        
      </Modal.Body>

      <Modal.Footer className="footerModal">
        <Button
          onClick={onHide}
        >
          {Constant.BUTTON.CLOSE}
        </Button>
				<Button
          onClick={submit}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalCompleteTransaction;
